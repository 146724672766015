import axios from 'axios';
import React, { useEffect, useState } from 'react';

const Singer = () => {
    const [data, setData] = useState([]);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [singerName, setSingerName] = useState('');
    const [currentSinger, setCurrentSinger] = useState(null);

    useEffect(() => {
        const fetchSinger = async () => {
            try {
                const { data } = await axios.get('http://localhost:3000/api/singer/all');
                console.log(data);
                setData(data);
            } catch (error) {
                console.log(error);
            }
        };
        fetchSinger();
    }, []);

    const handleAddSinger = async () => {
        try {
            await axios.post('http://localhost:3000/api/singer/add', { name: singerName });
            setShowAddModal(false);
            setSingerName('');
            const { data } = await axios.get('http://localhost:3000/api/singer/all');
            setData(data);
        } catch (error) {
            console.log(error);
        }
    };

    const handleEditSinger = async () => {
        try {
            await axios.put(`http://localhost:3000/api/singer/edit/${currentSinger.id}`, { name: singerName });
            setShowEditModal(false);
            setSingerName('');
            const { data } = await axios.get('http://localhost:3000/api/singer/all');
            setData(data);
        } catch (error) {
            console.log(error);
        }
    };

    const handleDeleteSinger = async () => {
        try {
            await axios.delete(`http://localhost:3000/api/singer/delete/${currentSinger.id}`);
            setShowDeleteModal(false);
            const { data } = await axios.get('http://localhost:3000/api/singer/all');
            setData(data);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className='dashboard-container'>
            <div className='d-flex justify-between'>
                <h2>لیست خواننده</h2>
                <div className="badge badge-primary pointer" onClick={() => setShowAddModal(true)}>اضافه کردن خواننده</div>
            </div>

            <div className='dashboard-card'>
                <div className='dashboard-card-body'>
                    <div className='dashboard-table'>
                        <div className='table-row table-th'>
                            <div className='table-cell'>آیدی</div>
                            <div className='table-cell'>نام</div>
                            <div className='table-cell'>عملیات</div>
                        </div>
                        {data.map(item => (
                            <div className='table-row table-td' key={item.id}>
                                <div className='table-cell'>{item.id}</div>
                                <div className='table-cell'>{item.name}</div>
                                <div className='table-cell'>
                                    <span className="pointer" onClick={() => { setCurrentSinger(item); setShowDeleteModal(true); }}>حذف</span>
                                    <span> | </span>
                                    <span className="pointer" onClick={() => { setCurrentSinger(item); setSingerName(item.name); setShowEditModal(true); }}>ویرایش</span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {showAddModal && (
                <div className='modal'>
                    <div className='modal-content'>
                        <span className='close' onClick={() => setShowAddModal(false)}>&times;</span>
                        <h3>اضافه کردن خواننده</h3>
                        <input
                            type='text'
                            value={singerName}
                            onChange={(e) => setSingerName(e.target.value)}
                            placeholder='نام خواننده'
                        />
                        <button onClick={handleAddSinger}>ثبت</button>
                        <button onClick={() => setShowAddModal(false)}>بستن</button>
                    </div>
                </div>
            )}

            {showEditModal && (
                <div className='modal'>
                    <div className='modal-content'>
                        <span className='close' onClick={() => setShowEditModal(false)}>&times;</span>
                        <h3>ویرایش خواننده</h3>
                        <input
                            type='text'
                            value={singerName}
                            onChange={(e) => setSingerName(e.target.value)}
                            placeholder='نام خواننده'
                        />
                        <button onClick={handleEditSinger}>ثبت</button>
                        <button onClick={() => setShowEditModal(false)}>بستن</button>
                    </div>
                </div>
            )}

            {showDeleteModal && (
                <div className='modal'>
                    <div className='modal-content'>
                        <span className='close' onClick={() => setShowDeleteModal(false)}>&times;</span>
                        <h3>آیا مطمئن هستید؟</h3>
                        <button onClick={handleDeleteSinger}>بله</button>
                        <button onClick={() => setShowDeleteModal(false)}>خیر</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Singer;
