import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router';

const Nahjol = () => {
    const navigate = useNavigate();
    const [data, setData] = useState({ khotbe: [], hekmat: [], nameh: [] });
    const [filter, setFilter] = useState('khotbe');
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [postTitle, setPostTitle] = useState('');
    const [currentPost, setCurrentPost] = useState(null);
    const [formData, setFormData] = useState({
        title: '',
        description: '',
        translatorName: '',
        postType: '',
        arabicText: [{ text: '' }],
        persianText: [{ text: '' }],
        englishText: [{ text: '' }]
    });


    console.log(formData);

    useEffect(() => {
        fetch('http://localhost:3000/api/nahj/')
            .then(response => response.json())
            .then(data => setData(data))
            .catch(error => console.error('Error fetching data:', error));
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleTextChange = (e, language, index) => {
        const { value } = e.target;
        const newTextArray = formData[language].map((item, idx) => idx === index ? { text: value } : item);
        setFormData({
            ...formData,
            [language]: newTextArray
        });
    };

    const handleAddTextItem = (language) => {
        setFormData({
            ...formData,
            [language]: [...formData[language], { text: '' }]
        });
    };

    const handleRemoveTextItem = (language, index) => {
        const newItems = formData[language].filter((_, idx) => idx !== index);
        setFormData({
            ...formData,
            [language]: newItems
        });
    };

    const handleAddPost = async () => {
        try {
            // Add API call here
            setShowAddModal(false);
            setFormData({
                title: '',
                description: '',
                translatorName: '',
                postType: '',
                arabicText: [{ text: '' }],
                persianText: [{ text: '' }],
                englishText: [{ text: '' }]
            });
            const { data } = await axios.get('http://localhost:3000/api/nahj/');
            setData(data);
        } catch (error) {
            console.log(error);
        }
    };

    const handleEditPost = async () => {
        try {
            await axios.put(`http://localhost:3000/api/nahj/edit/${filter}/${currentPost.postNumber}`, { title: postTitle });
            setShowEditModal(false);
            setPostTitle('');
            const { data } = await axios.get('http://localhost:3000/api/nahj/');
            setData(data);
        } catch (error) {
            console.log(error);
        }
    };

    const handleDeletePost = async () => {
        try {
            await axios.delete(`http://localhost:3000/api/nahj/delete/${filter}/${currentPost.postNumber}`);
            setShowDeleteModal(false);
            const { data } = await axios.get('http://localhost:3000/api/nahj/');
            setData(data);
        } catch (error) {
            console.log(error);
        }
    };


    return (
        <div className='dashboard-container'>
            <div className='dashboard-card'>
                <div className='dashboard-card-body'>
                    <div className='d-flex justify-between'>
                        <h2>لیست نهج البلاغه</h2>
                        <div className="badge badge-primary pointer" onClick={() => navigate(`/add-nahjolbalagheh`)}>اضافه کردن پست</div>
                    </div>
                    <div className='d-flex mr-10 mt-30'>
                        <div className='badge badge-primary ml-5 pointer' onClick={() => setFilter('khotbe')}>خطبه</div>
                        <div className='badge badge-green ml-5 pointer' onClick={() => setFilter('hekmat')}>حکمت</div>
                        <div className='badge badge-purple ml-5 pointer' onClick={() => setFilter('nameh')}>نامه</div>
                    </div>
                    <div className='dashboard-table'>
                        <div className='table-row table-th'>
                            <div className='table-cell'>شماره</div>
                            <div className='table-cell'>نام</div>
                            <div className='table-cell'>موضوع</div>
                            <div className='table-cell'>عملیات</div>
                        </div>
                        {data[filter].map(item => (
                            <div className='table-row table-td' key={item.id}>
                                <div className='table-cell'>{item.postNumber}</div>
                                <div className='table-cell'>{item.title}</div>
                                <div className='table-cell'>
                                    <div className={`badge badge-${filter === 'khotbe' ? 'orange' : filter === 'hekmat' ? 'green' : 'purple'}`}>
                                        {filter === 'khotbe' ? 'خطبه' : filter === 'hekmat' ? 'حکمت' : 'نامه'}
                                    </div>
                                </div>
                                <div className='table-cell'>
                                    <span className="pointer" onClick={() => { setCurrentPost(item); setShowDeleteModal(true); }}>حذف</span>
                                    <span> | </span>
                                    <span className="pointer" onClick={() => navigate(`/edit-nahjolbalagheh/${item.postType}/${item.postNumber}`)}>ویرایش</span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* api edit: http://localhost:3000/api/nahj/{postType}/{postNumber} */}

            {showAddModal && (
                <div className='modal'>
                    <div className='modal-content'>
                        <span className='close' onClick={() => setShowAddModal(false)}>&times;</span>
                        <h3>اضافه کردن پست</h3>
                        <input
                            type='text'
                            name='title'
                            value={formData.title}
                            onChange={handleInputChange}
                            placeholder='title'
                        />
                        <input
                            type='text'
                            name='description'
                            value={formData.description}
                            onChange={handleInputChange}
                            placeholder='description'
                        />
                        <input
                            type='text'
                            name='translatorName'
                            value={formData.translatorName}
                            onChange={handleInputChange}
                            placeholder='translatorName'
                        />
                        <input
                            type='text'
                            name='postType'
                            value={formData.postType}
                            onChange={handleInputChange}
                            placeholder='postType'
                        />
                        <div>
                            <h2>arabicText</h2>
                            {formData.arabicText.map((item, index) => (
                                <div key={index}>
                                    <input
                                        type='text'
                                        value={item.text}
                                        onChange={(e) => handleTextChange(e, 'arabicText', index)}
                                        placeholder='arabicText'
                                    />
                                    <button onClick={() => handleRemoveTextItem('arabicText', index)}>حذف</button>
                                </div>
                            ))}
                            <button onClick={() => handleAddTextItem('arabicText')}>اضافه کردن آیتم</button>
                        </div>
                        <div>
                            <h2>persianText</h2>
                            {formData.persianText.map((item, index) => (
                                <div key={index}>
                                    <input
                                        type='text'
                                        value={item.text}
                                        onChange={(e) => handleTextChange(e, 'persianText', index)}
                                        placeholder='persianText'
                                    />
                                    <button onClick={() => handleRemoveTextItem('persianText', index)}>حذف</button>
                                </div>
                            ))}
                            <button onClick={() => handleAddTextItem('persianText')}>اضافه کردن آیتم</button>
                        </div>
                        <div>
                            <h2>englishText</h2>
                            {formData.englishText.map((item, index) => (
                                <div key={index}>
                                    <input
                                        type='text'
                                        value={item.text}
                                        onChange={(e) => handleTextChange(e, 'englishText', index)}
                                        placeholder='englishText'
                                    />
                                    <button onClick={() => handleRemoveTextItem('englishText', index)}>حذف</button>
                                </div>
                            ))}
                            <button onClick={() => handleAddTextItem('englishText')}>اضافه کردن آیتم</button>
                        </div>
                        <button onClick={handleAddPost}>ثبت</button>
                        <button onClick={() => setShowAddModal(false)}>بستن</button>
                    </div>
                </div>
            )}

            {showEditModal && (
                <div className='modal'>
                    <div className='modal-content'>
                        <span className='close' onClick={() => setShowEditModal(false)}>&times;</span>
                        <h3>ویرایش پست</h3>
                        <input
                            type='text'
                            value={postTitle}
                            onChange={(e) => setPostTitle(e.target.value)}
                            placeholder='عنوان پست'
                        />
                        <button onClick={handleEditPost}>ثبت</button>
                        <button onClick={() => setShowEditModal(false)}>بستن</button>
                    </div>
                </div>
            )}

            {showDeleteModal && (
                <div className='modal'>
                    <div className='modal-content'>
                        <span className='close' onClick={() => setShowDeleteModal(false)}>&times;</span>
                        <h3>آیا مطمئن هستید؟</h3>
                        <button onClick={handleDeletePost}>بله</button>
                        <button onClick={() => setShowDeleteModal(false)}>خیر</button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Nahjol;

// export default withAuth(Page);
