import React from 'react'
import heartImg from '../assets/images/heart.png';
import { useNavigate } from 'react-router-dom';

const Sidebar = () => {
    const navigate = useNavigate();
    return (
        <div className="dashboard-sidebar">
            <div className='dashboard-container'>
                <div className='d-flex item item-image' onClick={() => navigate('/nahjolbalagheh/')}>
                    <img
                        src={heartImg}
                        alt="heart"
                        className='ml-15'
                    />
                    <div className=''>نهج البلاغه</div>
                </div>

                <div className='d-flex item' onClick={() => navigate('/add-adeieh/')}>
                    <img
                        src={heartImg}
                        alt="heart"
                        className='ml-15'
                    />
                    <div className=''>ادعیه</div>
                </div>

                <div className='d-flex item' onClick={() => navigate('/singer/')}>
                    <img
                        src={heartImg}
                        alt="heart"
                        className='ml-15'
                    />
                    <div className=''>صوت - قاری</div>
                </div>

                <div className='d-flex item'>
                    <img
                        src={heartImg}
                        alt="heart"
                        className='ml-15'
                    />
                    <div className=''>نهج البلاغه</div>
                </div>
            </div>
        </div>
    )
}

export default Sidebar