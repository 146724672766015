import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import deleteImg from '../../assets/images/delete-item.png';

const AddAdeieh = () => {
    const [formData, setFormData] = useState({
        title: '',
        description: '',
        audioFiles: [{ singerId: '', downloadLink: '' }],
        pdfLink: '',
        slug: '',
        content: [{ arabicText: '', persianText: '', description: '' }]
    });

    console.log(formData);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleAddAudioFile = () => {
        setFormData({
            ...formData,
            audioFiles: [...formData.audioFiles, { singerId: '', downloadLink: '' }]
        });
    };

    const handleRemoveAudioFile = (index) => {
        const newAudioFiles = formData.audioFiles.filter((_, idx) => idx !== index);
        setFormData({
            ...formData,
            audioFiles: newAudioFiles
        });
    };

    const handleAudioFileChange = (e, index) => {
        const { name, value } = e.target;
        const newAudioFiles = formData.audioFiles.map((item, idx) =>
            idx === index ? { ...item, [name]: value } : item
        );
        setFormData({
            ...formData,
            audioFiles: newAudioFiles
        });
    };

    const handleAddContentItem = () => {
        setFormData({
            ...formData,
            content: [...formData.content, { arabicText: '', persianText: '', description: '' }]
        });
    };

    const handleRemoveContentItem = (index) => {
        const newContent = formData.content.filter((_, idx) => idx !== index);
        setFormData({
            ...formData,
            content: newContent
        });
    };

    const handleContentChange = (e, index) => {
        const { name, value } = e.target;
        const newContent = formData.content.map((item, idx) =>
            idx === index ? { ...item, [name]: value } : item
        );
        setFormData({
            ...formData,
            content: newContent
        });
    };

    const handleDragEnd = (result) => {
        if (!result.destination) return;

        const newContent = Array.from(formData.content);
        const [reorderedItem] = newContent.splice(result.source.index, 1);
        newContent.splice(result.destination.index, 0, reorderedItem);

        setFormData({
            ...formData,
            content: newContent
        });
    };

    return (
        <div className='dashboard-container'>
            <div className='dashboard-card'>
                <div className='dashboard-card-body'>
                    <h3>اضافه کردن دعا</h3>

                    <div className='d-flex justify-between'>
                        <div style={{ flex: '0 0 60%' }}>
                            <h3>عنوان</h3>
                            <input
                                type='text'
                                name='title'
                                value={formData.title}
                                onChange={handleInputChange}
                                placeholder='عنوان'
                            />
                        </div>
                    </div>

                    <div className='mt-20'>
                        <h3>توضیحات</h3>
                        <input
                            name='description'
                            value={formData.description}
                            onChange={handleInputChange}
                            className='width-100'
                            placeholder='توضیحات (بیشتر برای سئو)'
                        />
                    </div>

                    <div className='mt-20'>
                        <h3>لینک PDF</h3>
                        <input
                            type='text'
                            name='pdfLink'
                            value={formData.pdfLink}
                            onChange={handleInputChange}
                            placeholder='لینک PDF'
                        />
                    </div>

                    <div className='mt-20'>
                        <h3>Slug</h3>
                        <input
                            type='text'
                            name='slug'
                            value={formData.slug}
                            onChange={handleInputChange}
                            placeholder='Slug'
                        />
                    </div>

                    <div className='inputs-body mt-20'>
                        <div className='d-flex justify-between'>
                            <h3>فایل‌های صوتی</h3>
                            <div className='badge badge-primary pointer' onClick={handleAddAudioFile}>اضافه کردن فایل صوتی</div>

                        </div>
                        {formData.audioFiles.map((audioFile, index) => (
                            <div key={index} className='d-flex items-center mb-10'>
                                <div>
                                    <input
                                        type='text'
                                        name='singerId'
                                        value={audioFile.singerId}
                                        onChange={(e) => handleAudioFileChange(e, index)}
                                        placeholder='آیدی خواننده'
                                        className='mr-10'
                                    />
                                </div>

                                <div>
                                    <input
                                        type='text'
                                        name='downloadLink'
                                        value={audioFile.downloadLink}
                                        onChange={(e) => handleAudioFileChange(e, index)}
                                        placeholder='لینک دانلود'
                                        className='mr-10'
                                    />
                                </div>

                                <div>
                                    <img
                                        className='height-25 pointer mr-5'
                                        src={deleteImg}
                                        alt='delete'
                                        onClick={() => handleRemoveAudioFile(index)}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className='inputs-body mt-20'>
                        <div className='d-flex justify-between'>
                            <h3>متن‌ها</h3>
                            <div className='badge badge-primary pointer' onClick={handleAddContentItem}>اضافه کردن آیتم</div>
                        </div>

                        <DragDropContext onDragEnd={handleDragEnd}>
                            <Droppable droppableId='content'>
                                {(provided) => (
                                    <div {...provided.droppableProps} ref={provided.innerRef}>
                                        {formData.content.map((item, index) => (
                                            <Draggable key={index} draggableId={`item-${index}`} index={index}>
                                                {(provided) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        className='d-flex flex-column mb-10'
                                                    >
                                                        <div className='d-flex items-center'>
                                                            <div className="p-number ml-5">{index + 1}</div>

                                                            <div>
                                                                <textarea
                                                                    name='arabicText'
                                                                    value={item.arabicText}
                                                                    onChange={(e) => handleContentChange(e, index)}
                                                                    placeholder='متن عربی'
                                                                    className='width-100 mr-10'
                                                                />
                                                            </div>

                                                            <div>
                                                                <textarea
                                                                    name='persianText'
                                                                    value={item.persianText}
                                                                    onChange={(e) => handleContentChange(e, index)}
                                                                    placeholder='ترجمه فارسی'
                                                                    className='width-100 mr-10'
                                                                />
                                                            </div>

                                                            <div>
                                                                <textarea
                                                                    name='description'
                                                                    value={item.description}
                                                                    onChange={(e) => handleContentChange(e, index)}
                                                                    placeholder='توضیحات'
                                                                    className='width-100'
                                                                />
                                                            </div>

                                                            <div>
                                                                <img
                                                                    className='height-25 pointer mr-5'
                                                                    src={deleteImg}
                                                                    alt='delete'
                                                                    onClick={() => handleRemoveContentItem(index)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </div>

                    <button>ثبت</button>
                </div>
            </div>
        </div>
    );
};

export default AddAdeieh;
