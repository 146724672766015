import { BrowserRouter, Router, Route, Routes, Navigate } from 'react-router-dom';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import './App.css';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import AddNahjol from './components/Nahjol/AddNahjol';
import Nahjol from './components/Nahjol/Nahjol';
import Singer from './components/Singer';
import EditNahjol from './components/Nahjol/EditNahjol';
import AddAdeieh from './components/Adeieh/AddAdeieh';

function App() {
  return (

    <div className="dashboard-page">
      <Header />
      <div className="dashboard-content">
        <Sidebar />
        <div className="dashboard-main">
          <ToastContainer autoClose={8000} />
          <Routes>

            <Route path="/nahjolbalagheh" element={<Nahjol />} />
            <Route path="/add-nahjolbalagheh" element={<AddNahjol />} />
            <Route path="/edit-nahjolbalagheh/:postType/:postNumber" element={<EditNahjol />} />

            <Route path="/add-adeieh" element={<AddAdeieh />} />

            <Route path="/singer" element={<Singer />} />
            {/* <Route path="/detection" element={<Detection />} />
        <Route path="/result/:id" element={<Result />} /> */}
          </Routes>


        </div>
      </div>
    </div>

  );
}

export default App;
