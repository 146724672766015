import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import deleteImg from '../../assets/images/delete-item.png';

const AddNahjol = () => {
    const [formData, setFormData] = useState({
        title: '',
        description: '',
        translatorName: '',
        postType: '',
        postNumber: '',
        arabicText: [{ text: '' }],
        persianText: [{ text: '' }],
        englishText: [{ text: '' }]
    });

    console.log(formData);

    const [activeTab, setActiveTab] = useState('arabic');

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleAddTextItem = (language) => {
        setFormData({
            ...formData,
            [language]: [...formData[language], { text: '' }]
        });
    };

    const handleRemoveTextItem = (language, index) => {
        const newItems = formData[language].filter((_, idx) => idx !== index);
        setFormData({
            ...formData,
            [language]: newItems
        });
    };

    const handleTextChange = (e, language, index) => {
        const { value } = e.target;
        const newTextArray = formData[language].map((item, idx) => idx === index ? { text: value } : item);
        setFormData({
            ...formData,
            [language]: newTextArray
        });
    };

    const onDragEnd = (result, language) => {
        if (!result.destination) return;

        const items = Array.from(formData[language]);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        setFormData({
            ...formData,
            [language]: items
        });
    };

    const renderTabContent = () => {
        const renderTextItems = (language) => (
            <div className='inputs-body'>
                <div className='d-flex justify-between'>
                    <h3>زبان {language}</h3>
                    <div className='badge badge-primary pointer' onClick={() => handleAddTextItem(`${language}Text`)}>اضافه کردن آیتم</div>
                </div>
                <DragDropContext onDragEnd={(result) => onDragEnd(result, `${language}Text`)}>
                    <Droppable droppableId={`${language}Text`}>
                        {(provided) => (
                            <div {...provided.droppableProps} ref={provided.innerRef} className='d-flex flex-column'>
                                {formData[`${language}Text`].map((item, index) => (
                                    <Draggable key={index} draggableId={`${language}Text-${index}`} index={index}>
                                        {(provided) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                className='d-flex align-items-center items-center mb-10'
                                            >

                                                <div class="p-number ml-5">{index+1}</div>
                                                <textarea
                                                    className='width-520'
                                                    type='text'
                                                    value={item.text}
                                                    onChange={(e) => handleTextChange(e, `${language}Text`, index)}
                                                    placeholder={`${language}Text`}
                                                />
                                                <div className='d-flex items-center'>
                                                    <img
                                                        className='mr-5 height-25 pointer'
                                                        src={deleteImg}
                                                        alt='delete'
                                                        onClick={() => handleRemoveTextItem(`${language}Text`, index)}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
        );

        switch (activeTab) {
            case 'arabic':
                return renderTextItems('arabic');
            case 'persian':
                return renderTextItems('persian');
            case 'english':
                return renderTextItems('english');
            default:
                return null;
        }
    };

    return (
        <div className='dashboard-container'>
            <div className='dashboard-card'>
                <div className='dashboard-card-body'>
                    <h3>اضافه کردن پست</h3>

                    <div className='d-flex justify-between'>
                        <div style={{ flex: '0 0 60%' }}>
                            <h3>عنوان</h3>
                            <input
                                type='text'
                                name='title'
                                value={formData.title}
                                onChange={handleInputChange}
                                placeholder='عنوان'
                            />
                        </div>

                        <div className='d-flex justify-between items-center' style={{ flex: '0 0 35%' }}>
                            <div tyle={{ flex: '1' }}>
                                <h3>شماره</h3>
                                <input
                                    type='text'
                                    name='postNumber'
                                    value={formData.postNumber}
                                    onChange={handleInputChange}
                                    placeholder='شماره (حکمت،نامه،خطبه)'
                                />
                            </div>

                            <div tyle={{ flex: '1' }}>
                                <h3>دسته</h3>
                                <select
                                    name='postType'
                                    value={formData.postType}
                                    onChange={handleInputChange}
                                >
                                    <option value=''>انتخاب کنید</option>
                                    <option value='hekmat'>حکمت</option>
                                    <option value='nameh'>نامه</option>
                                    <option value='khotbeh'>خطبه</option>
                                </select>
                            </div>

                            <div tyle={{ flex: '1' }}>
                                <h3>مترجم</h3>
                                <input
                                    type='text'
                                    name='translatorName'
                                    value={formData.translatorName}
                                    onChange={handleInputChange}
                                    placeholder='مترجم'
                                />
                            </div>
                        </div>
                    </div>

                    <div className='mt-20'>
                        <h3>توضیحات</h3>
                        <input
                            name='description'
                            value={formData.description}
                            onChange={handleInputChange}
                            className='width-100'
                            placeholder='توضیحات (بیشتر برای سئو)'
                        />
                    </div>

                    <div className='d-flex tabs mr-10 mt-30'>
                        <div className='badge badge-primary ml-5 pointer' onClick={() => setActiveTab('arabic')}>عربی</div>
                        <div className='badge badge-green ml-5 pointer' onClick={() => setActiveTab('persian')}>فارسی</div>
                        <div className='badge badge-purple pointer' onClick={() => setActiveTab('english')}>انگلیسی</div>
                    </div>

                    {renderTabContent()}

                    <button>ثبت</button>
                </div>
            </div>
        </div>
    );
};

export default AddNahjol;
